import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ExposeService } from '../expose-service/expose.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(
    private exposeService: ExposeService,
    private router: Router,
  ) {}

  async routeToExposeLink(exposeUrlSlug: string) {
    try {
      const exposeLink = await this.exposeService.getExposeLink(exposeUrlSlug);
      if (!exposeLink) {
        return false;
      }

      await this.exposeService.visitExposeLink(exposeUrlSlug);

      // no approval needed
      if (!exposeLink.brokerageContract && !exposeLink.cancellationPolicy) {
        this.router.navigate([exposeLink.exposeUrlSlug]);
      }

      // not approved - navigate to approval page
      if (!exposeLink.dateApproved) {
        return true;
      }

      return this.router.navigate([exposeLink.exposeUrlSlug]);
    } catch (err) {
      return false;
    }
  }
}
